import { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { getCustomerList } from '../services/customer.service';
import { errorHandler } from "../error";

//import { useSelect } from 'react-select-search';

export default function CustomerControl(props) {
    const { instance } = useMsal();
    const [value, setValue] = useState(props.value ?? null);
    const [allowEmpty] = useState(props.allowEmpty ?? false);
    const [readOnly, setReadOnly] = useState(props.readOnly ?? false);
    const onChange = props.onChange;
    const [customerList, setCustomerList] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const [data] = await Promise.all([
                getCustomerList(instance, { orderBy: [{ field: "name" }] })
            ]);
            setCustomerList(data);
        }
        fetchData();

    }, [allowEmpty, instance]);

    return (
        <select className="form-select" value={value ?? ""} disabled={readOnly} onChange={(event) => {
            const val = event.target.value;
            setValue(val);
            if (onChange) {
                onChange(val ? val : null, { value: val ? val : null, name: null });
            }
        }}>
            {allowEmpty ? (<option value="">-- Välj kund --</option>) : null}
            {customerList.length > 0 && (
                customerList.map((customer) => {
                    return (
                        <option key={customer.id} value={customer.id}>{customer.name}</option>
                    );
                })
            )}
        </select>
    )
}

// https://www.npmjs.com/package/react-select-search