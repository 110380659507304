import CardControl, { CardHeaderControl, CardBodyControl } from "../../components/CardControl";
import ToolbarControl from "../../components/ToolbarControl";
import SelectCheckbox from "../../components/SelectCheckbox";
import GroupByParentControl from "../../components/GroupByParentControl";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from 'react';
import DateCheckbox from "../../components/DateCheckbox";
import CategoryControl from "../../components/CategoryControl";
import CategoryCheckbox from "../../components/CategoryCheckbox";
import { Search } from 'react-bootstrap-icons';
import ResourceControl from "../../components/ResourceControl";
import UnitControl from "../../components/UnitControl";
import BooleanControl from "../../components/BooleanControl";
import BaseListControl, { currencyFormatter, periodFormatter } from '../../components/BaseListControl'
import { getCostSummaryList } from '../../services/cost.service';
import CostTypeControl from "../../components/CostTypeControl";
import CustomerControl from "../../components/CustomerControl";
import ProjectControl from "../../components/ProjectControl";
import PartControl from "../../components/PartControl";
import CategorySelectControl from "../../components/CategorySelectControl";

const columnDefinitions = [
    {
        headerName: "Resurs",
        field: "resourceName",
        width: 150,
        hide: false,
    },
    {
        headerName: "Typ",
        field: "costTypeName",
        width: 150,
        hide: false,
    },
    {
        headerName: "Period",
        field: "period",
        valueFormatter: periodFormatter,
        width: 90,
        hide: false,
    },
    {
        headerName: "Datum",
        field: "date",
        width: 110,
        hide: false,
    },
    {
        headerName: "Kund",
        field: "customerName",
        width: 250,
        hide: false,
    },
    {
        headerName: "Projekt",
        field: "projectName",
        width: 250,
        hide: false,
    },
    {
        headerName: "Projektdel",
        field: "partName",
        width: 250,
        hide: false,
    },
    {
        headerName: "Kategori",
        field: "categoryNamePath",
        width: 400,
        hide: false,
    },
    {
        headerName: "Kvantitet",
        field: "quantity",
        width: 110,
        type: ['numericColumn', 'rightAligned'],
        hide: false,
    },
    {
        headerName: "Enhet",
        field: "unitName",
        width: 85,
        hide: false,
    },
    {
        headerName: "Fakturanummer",
        field: "invoiceNumber",
        width: 150,
        hide: false,
    },
    {
        headerName: "Fakturerad",
        field: "isInvoiced",
        width: 120,
        hide: false,
    },
    {
        headerName: "Avvikelse",
        field: "isDeviation",
        width: 110,
        hide: false,
    },
    {
        headerName: "Belopp",
        field: "netAmount",
        valueFormatter: currencyFormatter,
        width: 130,
        type: ['numericColumn', 'rightAligned'],
        hide: false,
    },
    {
        headerName: "Moms",
        field: "vatAmount",
        valueFormatter: currencyFormatter,
        width: 130,
        type: ['numericColumn', 'rightAligned'],
        hide: true,
    },
    {
        headerName: "Antal",
        field: "count",
        width: 90,
        type: ['numericColumn', 'rightAligned'],
        hide: false,
    },
    {
        headerName: "Beskrivning",
        field: "description",
        width: 400,
        hide: false,
    },
]

export default function Summary() {
    const [filter, setFilter] = useState({});
    const [summaryList, setSummaryList] = useState([]);

    const { instance } = useMsal();

    const [unitToggle, setUnitToggle] = useState(false);
    const [resourceToggle, setResourceToggle] = useState(false);
    const [categoryToggle, setCategoryToggle] = useState(false);
    const [columns, setColumns] = useState(columnDefinitions);

    useEffect(() => {
        console.log({ filter });
        search();
    }, [filter]);


    //useEffect(() => {
    //    async function fetchData() {
    //        const [unitData, resourceData] = await Promise.all([
    //            getUnitList(instance),
    //            getResourceList(instance),
    //        ]);

    //        setUnits(unitData);
    //        setResources(resourceData);
    //    }


    //    fetchData();
    //}, [instance])

    async function search() {
        const header = await getCostSummaryList(instance, filter);

        columns.find((x) => x.field === "categoryNamePath").hide = !header.hasCategoryNamePath;
        columns.find((x) => x.field === "partName").hide = !header.hasPartName;
        columns.find((x) => x.field === "projectName").hide = !header.hasProjectName;
        columns.find((x) => x.field === "customerName").hide = !header.hasCustomerName;
        columns.find((x) => x.field === "resourceName").hide = !header.hasResourceName;
        columns.find((x) => x.field === "date").hide = !header.hasDate;
        columns.find((x) => x.field === "period").hide = !header.hasPeriod;
        columns.find((x) => x.field === "quantity").hide = !header.hasQuantity;
        columns.find((x) => x.field === "unitName").hide = !header.hasUnitName;
        columns.find((x) => x.field === "costTypeName").hide = !header.hasCostTypeName;
        columns.find((x) => x.field === "description").hide = !header.hasDescription;
        columns.find((x) => x.field === "invoiceNumber").hide = !header.hasInvoiceNumber;
        columns.find((x) => x.field === "isInvoiced").hide = !header.hasIsInvoiced;
        columns.find((x) => x.field === "isDeviation").hide = !header.hasIsDeviation;
        //    columns.find((x) => x.field === "netAmount").hide = !header.hasNetAmount;
        //    columns.find((x) => x.field === "vatAmount").hide = !header.hasVatAmount;
        //    columns.find((x) => x.field === "count").hide = !header.hasCount;
        setSummaryList(header.items);
        setColumns(columns);
    }
    function clear() {
        setFilter({});
    }

    return (
        <CardControl className="border-0">
            <CardHeaderControl>
                <ToolbarControl>
                    <button className="btn" onClick={() => {
                        search();
                    }}
                    >
                        <Search /> Sök
                    </button>
                    <button className="btn" onClick={() => {
                        clear();
                    }}
                    >
                        <Search /> Rensa
                    </button>
                </ToolbarControl>
            </CardHeaderControl>
            <CardBodyControl>
                <div className="row w-100">
                    <div className="col-12 col-md-6 col-lg-3">
                        <GroupByParentControl label={"Resurs"} isGroupBy={filter.groupByResource} onGroupByChanged={(isGroupBy) => {
                            setFilter({ ...filter, groupByResource: isGroupBy });
                        }}>
                            <ResourceControl value={filter.resourceId ?? null} allowEmpty={true} onChange={(resourceId, item) => {
                                setFilter({ ...filter, resourceId });
                            }} />
                        </GroupByParentControl>
                        <GroupByParentControl label={"Enhet"} isGroupBy={filter.groupByUnit} onGroupByChanged={(isGroupBy) => {
                            setFilter({ ...filter, groupByUnit: isGroupBy });
                        }}>
                            <UnitControl value={filter.unitId} allowEmpty={true} onChange={(unitId, item) => {
                                setFilter({ ...filter, unitId });
                            }} />
                        </GroupByParentControl>
                        <GroupByParentControl label={"Typ"} isGroupBy={filter.groupByCostType} onGroupByChanged={(isGroupBy) => {
                            setFilter({ ...filter, groupByCostType: isGroupBy });
                        }}>
                            <CostTypeControl value={filter.costTypeId ?? null} allowEmpty={true} onChange={(costTypeId, item) => {
                                setFilter({ ...filter, costTypeId });
                            }} />
                        </GroupByParentControl>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <GroupByParentControl label={"Datum (from)"} isGroupBy={filter.groupByDate} onGroupByChanged={(isGroupBy) => {
                            setFilter({ ...filter, groupByDate: isGroupBy });
                        }}>
                            <input type="date" value={filter.dateFrom ?? ""} className="form-control" onChange={(event) => {
                                setFilter({ ...filter, dateFrom: event.target.value });
                            }}></input>
                        </GroupByParentControl>
                        <div className="d-flex flex-column align-items-start w-100 my-2 mx-3">
                            <label className="form-label">Datum (tom)</label>
                            <input type="date" value={filter.dateTo ?? ""} className="form-control" onChange={(event) => {
                                setFilter({ ...filter, dateTo: event.target.value });
                            }}></input>
                        </div>
                        <GroupByParentControl label={"Period (from)"} isGroupBy={filter.groupByPeriod} onGroupByChanged={(isGroupBy) => {
                            setFilter({ ...filter, groupByPeriod: isGroupBy });
                        }}>
                            <input type="month" value={filter.periodFromFormat ?? ""} className="form-control" onChange={(event) => {
                                const val = Number(event.target.value.replace("-", ""));
                                setFilter({ ...filter, periodFrom: val === 0 ? null : val, periodFromFormat: event.target.value });
                            }}></input>
                        </GroupByParentControl>
                        <div className="d-flex flex-column align-items-start w-100 my-2 mx-3">
                            <label className="form-label">Period (tom)</label>
                            <input type="month" value={filter.periodToFormat ?? ""} className="form-control" onChange={(event) => {
                                const val = Number(event.target.value.replace("-", ""));
                                setFilter({ ...filter, periodTo: val === 0 ? null : val, periodToFormat: event.target.value });
                            }}></input>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <GroupByParentControl label={"Kund"} isGroupBy={filter.groupByCustomer} onGroupByChanged={(isGroupBy) => {
                            setFilter({ ...filter, groupByCustomer: isGroupBy });
                        }}>
                            <CustomerControl value={filter.customerId ?? null} allowEmpty={true} onChange={(customerId, item) => {
                                setFilter({ ...filter, customerId, projectId: null, partId: null, category: null });
                            }} />
                        </GroupByParentControl>
                        <GroupByParentControl label={"Projekt"} isGroupBy={filter.groupByProject} onGroupByChanged={(isGroupBy) => {
                            setFilter({ ...filter, groupByProject: isGroupBy });
                        }}>
                            <ProjectControl value={filter.projectId ?? null} customerId={filter.customerId} allowEmpty={true} onChange={(projectId, item) => {
                                setFilter({ ...filter, projectId, partId: null, category: null });
                            }} />
                        </GroupByParentControl>
                        <GroupByParentControl label={"Projektdel"} isGroupBy={filter.groupByPart} onGroupByChanged={(isGroupBy) => {
                            setFilter({ ...filter, groupByPart: isGroupBy });
                        }}>
                            <PartControl value={filter.partId ?? null} projectId={filter.projectId} allowEmpty={true} onChange={(partId, item) => {
                                setFilter({ ...filter, partId, category: null });
                            }} />
                        </GroupByParentControl>
                        <GroupByParentControl label={"Kategori"} isGroupBy={filter.groupByCategory} onGroupByChanged={(isGroupBy) => {
                            setFilter({ ...filter, groupByCategory: isGroupBy });
                        }}>
                            <CategorySelectControl value={filter.categoryId ?? null} partId={filter.partId} allowEmpty={true} onChange={(categoryId, item) => {
                                setFilter({ ...filter, categoryId });
                            }} />
                        </GroupByParentControl>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <GroupByParentControl label={"Fakturerad"} isGroupBy={filter.groupByIsInvoiced} onGroupByChanged={(isGroupBy) => {
                            setFilter({ ...filter, groupByIsInvoiced: isGroupBy });
                        }}>
                            <BooleanControl value={filter.isInvoiced ?? null} onChange={(e) => {
                                setFilter({ ...filter, isInvoiced: e });
                            }} />
                        </GroupByParentControl>
                        <GroupByParentControl label={"Fakturanummer"} isGroupBy={filter.groupByInvoice} onGroupByChanged={(isGroupBy) => {
                            setFilter({ ...filter, groupByInvoice: isGroupBy });
                        }}>
                            <input type="text" className="form-control" readOnly={true} />
                        </GroupByParentControl>
                        <GroupByParentControl label={"Avvikelse"} isGroupBy={filter.groupByIsDeviation} onGroupByChanged={(isGroupBy) => {
                            setFilter({ ...filter, groupByIsDeviation: isGroupBy });
                        }}>
                            <BooleanControl value={filter.isDeviation ?? null} onChange={(e) => {
                                setFilter({ ...filter, isDeviation: e });
                            }} />
                        </GroupByParentControl>
                        <GroupByParentControl label={"Beskrivning"} isGroupBy={filter.groupByDescription} onGroupByChanged={(isGroupBy) => {
                            setFilter({ ...filter, groupByDescription: isGroupBy });
                        }}>
                            <input type="text" className="form-control" readOnly={true} />
                        </GroupByParentControl>
                    </div>
                </div>
                <BaseListControl columns={columns} list={summaryList} tableName="Summering" />
            </CardBodyControl>
        </CardControl>
    );
}

export async function loader({ msalInstance, request }) {
    //    const categoryId = new URL(request.request.url).searchParams.get('categoryId') ?? null;
    //    const resourceId = new URL(request.request.url).searchParams.get('resourceId') ?? null;
    //    const date = new URL(request.request.url).searchParams.get('date') ?? new Date().toISOString().split('T')[0];

    //    return { cost: { categoryId, resourceId, date, isLocked: false } };
    return {};
}
