import { useState, useMemo, useEffect, useCallback, useRef } from "react";
//import {
//    useLoaderData,
//} from "react-router-dom";

import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme


export default function BaseListControl({ columns, list, onRowClicked, rowSelection, getRowId, onSelectionChanged, gridRef, onRowDataUpdated, gridId, tableName }) {
    gridRef = useRef(null);
    const [colDefs] = useState(columns);
    const defaultColDef = useMemo(() => ({
        filter: true // Enable filtering on all columns
    }));
    let rowMultiSelectWithClick = true;

    const [gridApi, setGridApi] = useState(null);

    // Callback to handle grid ready event
    const onGridReady = useCallback((params) => {
        if (params) {
            setGridApi(params.api);
            gridRef.current = params.api;
        }
    }, [gridRef]);

    if (!onRowClicked) {
        onRowClicked = (e) => { };
    }

    if (rowSelection === 'multiple') {
        rowMultiSelectWithClick = true;
    }

    const pinnedBottomRowData = useMemo(() => {
        let sumNetAmount = list.reduce((sum, item) => sum + item.netAmount, 0);
        let sumQuantity = list.reduce((sum, item) => sum + item.quantity, 0);
        let sumCount = list.reduce((sum, item) => sum + item.count, 0);
        let sumVatAmount = list.reduce((sum, item) => sum + item.vatAmount, 0);
        let sumGrossAmount = list.reduce((sum, item) => sum + item.grossAmount, 0);
        let sumInvoiceAmount = list.reduce((sum, item) => sum + item.invoiceAmount, 0);
        // let unitPrice = list.reduce((sum, item) => sum + item.unitPrice, 0) / list.length;
        switch (tableName) {
            case "Tidsredovisning":
                return [
                    {
                        id: "00000000-0000-0000-0000-000000000000",
                        partNamePath: "Totalt: " + list.length,
                        netAmount: sumNetAmount,
                        quantity: sumQuantity,
                        unitPrice: -1, //unitPrice,
                    }
                ];
            case "Projekt":
                return [
                    {
                        id: "00000000-0000-0000-0000-000000000000",
                        number: "Totalt: " + list.length,
                    }
                ];
            case "Fakturering":
                return [
                    {
                        id: "00000000-0000-0000-0000-000000000000",
                        netAmount: sumNetAmount,
                        vatAmount: sumVatAmount,
                        grossAmount: sumGrossAmount,
                        invoiceAmount: sumInvoiceAmount,
                        number: "Totalt: " + list.length,
                    }
                ];

            case "SkapaFaktura2":
                return [
                    {
                        id: "00000000-0000-0000-0000-000000000000",
                        number: "Totalt: " + list.length,
                        netAmount: sumNetAmount,
                    }
                ];

            case "SkapaFaktura3":
                return [
                    {
                        id: "00000000-0000-0000-0000-000000000000",
                        partNumber: "Totalt: " + list.length,
                        netAmount: sumNetAmount,
                    }
                ];

            case "SkapaFaktura4":
                return [
                    {
                        id: "00000000-0000-0000-0000-000000000000",
                        resourceName: "Totalt: " + list.length,
                        netAmount: sumNetAmount,
                        unitPrice: -1
                    }
                ];

            case "Summering":
                return [
                    {
                        id: "00000000-0000-0000-0000-000000000000",
                        netAmount: sumNetAmount,
                        vatAmount: sumVatAmount,
                        quantity: sumQuantity,
                        count: sumCount
                    }
                ];
            default:
                return [];
        }
    }, [list, tableName]);

    const onRowUpdate = useCallback((event) => {
        if (gridApi)
            gridApi.setGridOption('pinnedBottomRowData', pinnedBottomRowData);
        if (onRowDataUpdated) {
            onRowDataUpdated(event);
        }
    }, [gridApi, onRowDataUpdated, pinnedBottomRowData]);

    const rowStyle = (params) => {
        if (params.node.rowPinned) {
            return {
                'fontWeight': 'bold',
                'borderTop': "2px solid #dddddd"
            };
        }
        else return null
    }

    const getTableHeight = () => {
        switch (tableName) {
            case "Tidsredovisning":
            case "Fakturering":
                return 263;

            case "Projekt":
            case "Kunder":
                return 143;

            case "SkapaFaktura2":
            case "SkapaFaktura3":
            case "SkapaFaktura4":
                return 183;

            case "Summering":
                return 530;

            default:
                return 0;
        }
    }

    let height = "calc(-" + getTableHeight() + "px + 100vh)";
    return (
        
        <div className="ag-theme-quartz" style={{ height: height}}>

        {/*<div className="ag-theme-quartz" style={{ height: 100 + 'vh' }}>*/}
        {/*<div className="ag-theme-quartz" style="height: 100vh">*/}
            <AgGridReact
                getRowStyle={rowStyle}
                pinnedBottomRowData={pinnedBottomRowData}
                rowData={list}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}
                pagination={true}
                onRowClicked={onRowClicked}
                rowSelection={rowSelection}
                getRowId={getRowId}
                rowMultiSelectWithClick={rowMultiSelectWithClick}
                onSelectionChanged={onSelectionChanged}
                ref={gridRef}
                gridId={gridId}
                onGridReady={onGridReady}
                onRowDataUpdated={onRowUpdate}
            />
        </div>
    );
}

export function currencyFormatter(params) {
    if (params.value === -1) {
        return "";
    }
    return formatNumber(params.value) + ' kr';
}
export function formatNumber(number) {
    if (number === undefined) return -1;
    return number.toLocaleString('sv-SE', { minimumFractionDigits: 2 });
}
export function periodFormatter(params) {
    if (params.value === null || params.value === -1 || isNaN(params.value)) {
        return "";
    }
    const year = (params.value / 100).toFixed(2).toString();
    return year.replace('.', '-');
}

// https://www.ag-grid.com/react-data-grid/getting-started/