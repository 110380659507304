import { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { getCostTypeList } from '../services/cost-type.service';
import { errorHandler } from "../error";

//import { useSelect } from 'react-select-search';

export default function CostTypeControl(props) {
    const { instance } = useMsal();
    const [value, setValue] = useState(props.value ?? "");
    //const [options, setOptions] = useState([]);
    const [allowEmpty] = useState(props.allowEmpty ?? false);
    const [readOnly, setReadOnly] = useState(props.readOnly ?? false);
    const onChange = props.onChange;
    const [costTypeList, setCostTypeList] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const [data] = await Promise.all([
                getCostTypeList(instance, { orderBy: [{ field: "sortOrder" }] })
            ]);
            if (!value && !allowEmpty) {
                let defaultCostType = data.find((x) => (x.isDefault));
                if (defaultCostType) {
                    setValue(defaultCostType.id);
                    if (onChange) {
                        onChange(defaultCostType.id, defaultCostType);
                    }
                }
            }
            setCostTypeList(data);
        }
        fetchData();

    }, [allowEmpty, instance]);

    useEffect(() => {
        setReadOnly(props.readOnly ?? false);
        if (props.value !== undefined) {
            setValue(props.value ?? "");
        }
    }, [props]);

    return (
        <select className="form-select" value={value} disabled={readOnly} onChange={(event) => {
            const val = event.target.value;
            setValue(val);
            if (onChange) {
                onChange(val ? val : null, { value: val ? val : null, name: null });
            }
        }}>
            {allowEmpty ? (<option value="">-- Välj typ --</option>) : null}
            {costTypeList.length > 0 && (
                costTypeList.map((costType) => {
                    return (
                        <option key={costType.id} value={costType.id}>{costType.name}</option>
                    );
                })
            )}
        </select>
    )
}

// https://www.npmjs.com/package/react-select-search