import { useState, useEffect, useRef } from 'react'
import { getCategoryList, getCategory } from '../services/category.service';
import { useMsal } from "@azure/msal-react";
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { errorHandler } from "../error";
import { Folder, Search, ChevronDown, ChevronRight } from 'react-bootstrap-icons';

export default function CategoryControl(props) {
    const { instance } = useMsal();
    const [value, setValue] = useState(props.value ?? null);
    const [showInfo, setShowInfo] = useState(false);
    const [name, setName] = useState("");
    const onChange = props.onChange;

    useEffect(() => {
        if (value) {
            getCategory(instance, value).then((cat) => {
                let text = cat.projectName + ' / ' + cat.partName + ' / ' + cat.namePath;
                setName(text);
            }).catch(errorHandler);
        } else {
            setName("");
        }
    }, [value, instance]);

    useEffect(() => {
        setValue(props.value ?? null);
    }, [props]);
    return (
        <>
            <div className="input-group">
                <input type="text" className="form-control" value={name} readOnly={true} onClick={() => { setShowInfo(!showInfo) }} />
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button" data-bs-toggle="modal" data-bs-target="#selectCategoryModal">
                        <Folder />
                    </button>
                </div>
            </div>
            <CategoryModalControl value={value} onChange={(e) => { if (e) { setValue(e); onChange(e); } }} />
            {!showInfo ? null : (
                <div>Visa info</div>

            )}
        </>
    )
}

export function CategoryModalControl(props) {
    const { instance } = useMsal();
    const [value, setValue] = useState(props.value ?? null);
    const [tree, setTree] = useState([]);
    const [text, setText] = useState("");
    const [expanded, setExpanded] = useState([]);
    const closeButtonRef = useRef(null);
    const onChange = (e) => {
        props.onChange(e);
        closeButtonRef.current.click();
    };

    useEffect(() => {
        setValue(props.value ?? null);
        search();
    }, [props]);

    const search = () => {
        let filter = { searchTerm: text, canRegisterCost: true, orderBy: [{ field: "partNamePath" }, { field: "NamePath" }] };
        getCategoryList(instance, filter).then((result) => {
            let list = [];
            let expandList = [];
            for (var i = 0; i < result.length; i++) {
                var item = result[i];
                let cus = list.find((x) => x.id === item.customerId);
                if (!cus) {
                    cus = { id: item.customerId, label: item.customerName, childs: [], expanded: true, type: "cus" };
                    list.push(cus);
                }

                let pro = cus.childs.find((x) => x.id === item.projectId);
                if (!pro) {
                    pro = { id: item.projectId, label: item.projectName, childs: [], expanded: false, type: "pro" };
                    cus.childs.push(pro);
                }

                let par = pro.childs.find((x) => x.id === item.partId);
                if (!par) {
                    par = { id: item.partId, label: item.partName, childs: [], expanded: false, type: "par" };
                    pro.childs.push(par);
                }

                let cat = { id: item.id, label: item.namePath, childs: [], expanded: false, type: "cat" };
                par.childs.push(cat);
                if (item.name.toLowerCase().includes(text.toLowerCase()) || item.id === value) {
                    if (text || item.id === value)
                        expandList.push(cus.id, pro.id, par.id, cat.id)
                    //setExpanded([cus.id, pro.id, par.id, cat.id]);

                } else if (item.partName.toLowerCase().includes(text.toLowerCase()) || item.id === value) {
                    if (text || item.id === value) {
                        expandList.push(cus.id, pro.id, par.id);
                    }

                } else if (item.projectName.toLowerCase().includes(text.toLowerCase()) || item.id === value) {
                    if (text || item.id === value) {
                        expandList.push(cus.id, pro.id);
                    }
                } else if (value === item.id) {
                    expandList.push(cus.id, pro.id, par.id, cat.id)
                }
            }
            setExpanded([...new Set(expandList)].slice(0, 10));
            setTree(list);
        }).catch(errorHandler);
    }

    return (
        <div className="modal fade" tabIndex="-1" id="selectCategoryModal">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Välj kategori</h5>
                        <button type="button" ref={closeButtonRef} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" value={text} placeholder="Sök" onKeyUp={(e) => { if (e.key === 'Enter') { search(); } }} onChange={(e) => { setText(e.target.value); }} />
                            <button className="btn btn-outline-secondary" type="button" onClick={search}>
                                <Search />
                            </button>
                        </div>
                        <div className="w-100 d-block"></div>
                        <CategoryTreeControl list={tree} expanded={expanded} value={value} onChange={onChange} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export function CategoryTreeControl(props) {

    const list = props.list;
    const [value, setValue] = useState(props.value);
    const [expanded, setExpanded] = useState(props.expanded);
    const onChange = (e) => { props.onChange(e); };

    useEffect(() => {
        setValue(props.value);
        setExpanded(props.expanded);
    }, [props]);

    const renderTree = (node) => (
        <TreeItem
            nodeId={node.id}
            key={node.id}
            label={node.label}
            onClick={() => {
                if (node.type === "cat") {
                    onChange(node.id);
                }
            }}
        >
            {Array.isArray(node.childs) ? node.childs.map((x) => renderTree(x)) : null}
        </TreeItem>
    );

    return (
        <TreeView
            defaultCollapseIcon={<ChevronDown />}
            defaultExpandIcon={<ChevronRight />}
            expanded={expanded}
            defaultSelected={[value]}
            onNodeToggle={(event, nodeIds) => { setExpanded(nodeIds); } }
            sx={{ flexGrow: 1, overflowY: 'auto' }}
        >
            {list.map((x) => renderTree(x))}
        </TreeView>
    )
}