import React, { useState } from "react";
// import { useMsal } from "@azure/msal-react";
import {
    useLoaderData,
    useNavigate,
    NavLink,
} from "react-router-dom";
import { getCategoryList } from '../../services/category.service';
import { getPart } from '../../services/part.service';
//import { serializeQueryString } from "../../helpers/url.helper";

import BaseListControl from '../../components/BaseListControl'
import CardControl, { CardHeaderControl, CardBodyControl } from "../../components/CardControl";
import ToolbarControl from "../../components/ToolbarControl";
import { PlusCircle } from 'react-bootstrap-icons';

const columns = [
    {
        headerName: "Förälder",
        field: "namePath",
        filter: true,
        width: 300
    },
    {
        headerName: "Namn",
        field: "name",
        filter: true,
    },
    {
        headerName: "Projektdelsnr",
        field: "partNumber",
        filter: true,
    },
    {
        headerName: "Projektdel",
        field: "partNamePath",
        filter: true,
        width: 300
    },
    {
        headerName: "Stängd",
        field: "isClosed",
        filter: true,
    },
    {
        headerName: "Ärvd",
        field: "isInherited",
        filter: true,
    },
]
let navigate;

export default function CategoryIndex() {
    // const { instance } = useMsal();
    const { categoryList, loaderFilter, part, parent } = useLoaderData();
    //const [filter] = useState(loaderFilter);
    navigate = useNavigate();

    //const search = () => {
    //    console.log({ filter });
    //    navigate("?" + serializeQueryString(filter));
    //};

    return (
        <CardControl className="border-0">
            <CardHeaderControl>
                <ToolbarControl>
                    <div className="btn-group" role="group">
                        <NavLink className="btn" to={"create?partId=" + part.id + "&parentId=" + (parent?.id ?? "")}>
                            <PlusCircle /> Skapa
                        </NavLink>
                    </div>
                </ToolbarControl>
            </CardHeaderControl>
            <CardBodyControl>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <NavLink title="Kund" to={"../../customer/" + part.project.customerId}>{part.project.customerName}</NavLink>
                        </li>
                        <li className="breadcrumb-item">
                            <NavLink title="Projekt" to={"../../project/" + part.projectId}>{part.projectName}</NavLink>
                        </li>
                        <li className="breadcrumb-item">
                            <NavLink title="Projektdel" to={"../../part/" + part.id}>{part.name}</NavLink>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Kategorier</li>
                    </ol>
                </nav>
                <BaseListControl columns={columns} list={categoryList} onRowClicked={rowClicked} />
            </CardBodyControl>
        </CardControl>

    );
}

function rowClicked(e) {
    const id = e.data.id;
    navigate("/category/" + id);
}

export async function loader({ msalInstance, request, params }) {

    //console.log({ msalInstance: msalInstance, request: request.request, params: request.params });

    let filter = {
        partId: new URL(request.request.url).searchParams.get('partId'),
        parentId: new URL(request.request.url).searchParams.get('parentId') ?? null,
        orderBy: [{ field: "namePath" }]
    }
    const categoryList = await getCategoryList(msalInstance, filter);
    const part = await getPart(msalInstance, filter.partId, ["Project"]);
    const parent = (filter.parentId) ? await getPart(msalInstance, filter.parentId, ['Parent']) : null;

    return { categoryList, loaderFilter: filter, part, parent };
}
