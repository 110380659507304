import { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { getCategoryList } from '../services/category.service';
import { errorHandler } from "../error";

//import { useSelect } from 'react-select-search';

export default function CategorySelectControl(props) {
    const { instance } = useMsal();
    const [value, setValue] = useState(props.value ?? null);
    const [partId, setPartId] = useState(props.partId ?? null);
    const [allowEmpty] = useState(props.allowEmpty ?? false);
    const [readOnly, setReadOnly] = useState(props.readOnly ?? false);
    const onChange = props.onChange;
    const [categoryList, setCategoryList] = useState([]);

    useEffect(() => {
        if ((props.partId ?? null) !== partId) {
            setPartId(props.partId);
        }
    }, [props]);
    useEffect(() => {
        async function fetchData() {
            const [data] = await Promise.all([
                getCategoryList(instance, { partId, orderBy: [{ field: "name" }] })
            ]);
            setCategoryList(data);
        }

        if (partId) {
            fetchData();
            setReadOnly(false);
        }
        else {
            setReadOnly(true);
            setValue(null);
            setCategoryList([]);
            if (onChange) {
                onChange(null, { value: null, name: null });
            }
        }

    }, [partId, instance]);

    return (
        <select className="form-select" value={value ?? ""} disabled={readOnly} onChange={(event) => {
            const val = event.target.value;
            setValue(val);
            if (onChange) {
                onChange(val ? val : null, { value: val ? val : null, name: null });
            }
        }}>
            {allowEmpty ? (<option value="">-- Välj kategori --</option>) : null}
            {categoryList.length > 0 && (
                categoryList.map((category) => {
                    return (
                        <option key={category.id} value={category.id}>{category.name}</option>
                    );
                })
            )}
        </select>
    )
}
