import { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { getProjectList } from '../services/project.service';
import { errorHandler } from "../error";

//import { useSelect } from 'react-select-search';

export default function ProjectControl(props) {
    const { instance } = useMsal();
    const [value, setValue] = useState(props.value ?? null);
    const [customerId, setCustomerId] = useState(props.customerId ?? null);
    const [allowEmpty] = useState(props.allowEmpty ?? false);
    const [readOnly, setReadOnly] = useState(props.readOnly ?? false);
    const onChange = props.onChange;
    const [projectList, setProjectList] = useState([]);

    useEffect(() => {
        if ((props.customerId ?? null) !== customerId) {
            setCustomerId(props.customerId);
        }
    }, [props]);
    useEffect(() => {
        async function fetchData() {
            const [data] = await Promise.all([
                getProjectList(instance, { customerId, orderBy: [{ field: "name" }] })
            ]);
            setProjectList(data);
        }

        if (customerId) {
            fetchData();
            setReadOnly(false);
        }
        else {
            setReadOnly(true);
            setValue(null);
            setProjectList([]);
            if (onChange) {
                onChange(null, { value: null, name: null });
            }
        }

    }, [customerId, instance]);

    return (
        <select className="form-select" value={value ?? ""} disabled={readOnly} onChange={(event) => {
            const val = event.target.value;
            setValue(val);
            if (onChange) {
                onChange(val ? val : null, { value: val ? val : null, name: null });
            }
        }}>
            {allowEmpty ? (<option value="">-- Välj projekt --</option>) : null}
            {projectList.length > 0 && (
                projectList.map((project) => {
                    return (
                        <option key={project.id} value={project.id}>{project.name}</option>
                    );
                })
            )}
        </select>
    )
}
